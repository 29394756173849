
export default [
  {
    path: '/:pathMatch(.*)*',
    redirect: "/erro"
  },
  {
    path: '/erro',
    name: 'erro',
    component: () => import('../components/app/erro/Erro.vue'),
    meta: ({
      title: 'Essa página não existe... | Level Member',
    })
  },
  {
    path: '/',
    name: 'inicio',
    component: () => import('../components/app/inicio/Inicio.vue'),
    meta: ({
      title: 'Buscar Clínica | OligoCheck',
    })
  },
  {
    path: '/busca',
    name: 'busca',
    component: () => import('../components/app/busca/Busca.vue'),
    meta: ({
      title: 'Busca | OligoCheck',
    })
  },
]
